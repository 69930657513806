.container {
    align-items: center;
    display: flex;
    padding: 10px;
    gap: 10%;
    justify-content: center;
    flex-wrap: wrap;
}
.container-item {
    background: none;
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 50px;
    padding: 20px;
    text-align: center;
    height: 42px;
}
.guess {
    display: flex;
    justify-content: space-around;
}
.response {
    display: flex;
    justify-content: space-around;
}
.response .grid-item {
    background: none;
}
.game-btn {
    margin: 0.5rem;
    color: black !important;
    border: none;
}
#key-btn {
    color: #27ae32 !important;
}
#unlock-btn {
    color: #58D68D !important;
}
.restart-btn {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    height: 50px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    border-radius: 25px;
    color: rgb(234, 234, 234);
    transition: all 0.5s ease;
    background-color: rgba(255, 0, 0, 0.459) !important;
    font-size: 1.25rem;
    width: 10rem !important;
    margin: 0.5rem;
}
.restart-btn:hover {
    transform: scale(1.1);
    background-color: rgba(255, 0, 0, 0.604) !important;
    color: white;
}
.white {
    background-color: white;
}
.black {
    background-color: black;
    border: 0.5px solid rgba(255, 255, 255, 0.8);
}
.blue {
    background-color: rgb(49, 108, 244);
}
.green {
    background-color: rgb(64, 133, 88);
}
.red {
    background-color: rgb(203, 68, 74);
}
.yellow {
    background-color: rgb(255, 255, 84);
}
.orange {
   background-color: rgb(242, 169, 59);
}

@media screen and (max-width: 520px) {
    .container {
        gap: 20px;
        flex-direction: column;
    }
    .gamebutton {
        width: 50px;
        height: 22px;
        border-width: 0 !important;
        padding: 0 !important;
        order: 3 !important;
    }
    .guessForm {
        display: flex;
    }
}
.invalid:hover {
/* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
}
/* Animation for key shake when guess is invalid */
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }